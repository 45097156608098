/**
 * A function to deal with youtube iframe when there is no cookies. This object is a singleton.
 *
 * @class FcaYoutubeVideoNoCookie
 * @singleton
 */

let haveCookies = false;

window.FcaYoutubeVideoNoCookie = (function () {
    'use strict';

    return {
        updateYoutubeIframe() {
            const iframeList = document.getElementsByTagName("iframe");
            for(let i = 0; i < iframeList.length; i++) {
                const iframe = iframeList[i];
                var src = iframe.getAttribute('src');
                if (src && typeof src=="string") {
                    var noCookieSrc = this.updateYoutubeSrc(src);
                    if(noCookieSrc != src) {
                        iframe.setAttribute('src', noCookieSrc);
                    }
                }
            }
        },
        updateYoutubeSrc(src) {
			if (typeof(src) != "string") return src
            let initalUrl = 'youtube.com';
            let replacedUrl = 'youtube-nocookie.com';
            if (haveCookies) {
                initalUrl = 'youtube-nocookie.com';
                replacedUrl = 'youtube.com';
            }
            if (src && src.includes(initalUrl)) {
                return src.replace(initalUrl, replacedUrl);
            }
            return src
        },
        detectCookies() {
            if (window.FcaCookieChecker) {
                window.FcaCookieChecker.isCookieActivated_ByDisplayName("youtube.com").then(
                    result => {
                        haveCookies = result
                        this.updateYoutubeIframe();
                    }
                );
            }
        }
    };
})();


window.FcaYoutubeVideoNoCookie.detectCookies();
